import React, { useState, useRef } from "react";
import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/images/logo/Claimtec-logo.png";
import { CiSquarePlus } from "react-icons/ci";
import { RiDashboard3Line } from "react-icons/ri";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { PiSignOutBold } from "react-icons/pi";
import useConfig from "../../hooks/useConfig";
import * as Storage from "../../store/LocalStorage";

export default function Header() {
  const location = useLocation();
  const config = useConfig();
  const navigate = useNavigate();
  const { logout, admin } = useAuth();

  const onLogout = () => {
    logout();
    Storage.remove(config.sessionKey);
    console.log("terminal Cleared");
  };

  console.log(admin);

  // Dropdown state and handlers
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShow(!show);
  };

  const [notificationShow, setNotificationShow] = useState(false);
  const notificatinRef = useRef(null);

  const toggleDropdownNotification = () => {
    setNotificationShow(!notificationShow);
  };

  // Active item state
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className="header-main">
      <div className="left-header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div className="right-header">
        <div className="left-links">
          <ul>
            <li
              style={{ cursor: "pointer" }}
              className={activeItem === "operations" ? "operation-class" : ""}
              onClick={() => {
                handleItemClick("operations");
                navigate("/operations");
              }}
            >
              <p>Operations</p>
            </li>
            <li
              ref={dropdownRef}
              className={`dropdown ${show ? "show" : ""} `}
              onClick={() => handleItemClick("dashboard")}
            >
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={show}
                onClick={toggleDropdown}
                style={{
                  backgroundColor: "#d52429",
                  borderColor: "#d52429",
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <RiDashboard3Line size={20} /> Dashboard
              </button>
              <div
                className={`dropdown-menu ${show ? "show" : ""}`}
                style={{ marginTop: "2.5rem" }}
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item" href="#nogo">
                  Active Services
                </a>
                <a className="dropdown-item" href="#nogo">
                  Requests
                </a>
              </div>
            </li>
            <li onClick={() => handleItemClick("create-agent")}>
              <button
                className="create-agent"
                onClick={() => navigate("/create-agent")}
              >
                <CiSquarePlus size={22} />
                Create Agent
              </button>
            </li>
            <li
              className={activeItem === "agents" ? "operation-class" : ""}
              onClick={() => {
                handleItemClick("agents");
                navigate("/agents");
              }}
              style={{ cursor: "pointer", margin: "0 1rem" }}
            >
              <p>Agents</p>
            </li>
            <li
              className={activeItem === "rates" ? "operation-class" : ""}
              onClick={() => {
                handleItemClick("rates");
                navigate("/case-rates");
              }}
              style={{ cursor: "pointer", margin: "0 1rem", marginLeft: '0' }}
            >
              <p>Case Rates</p>
            </li>
          </ul>
        </div>
        <div className="right-links">
          <ul>
            <li
              ref={notificatinRef}
              className={`dropdown ${notificationShow ? "show" : ""}`}
            >
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButtonNotification"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={notificationShow}
                onClick={toggleDropdownNotification}
                style={{
                  backgroundColor: "#d52429",
                  borderColor: "#d52429",
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <FaBell size={20} />
              </button>
              <div
                className={`dropdown-menu ${notificationShow ? "show" : ""}`}
                aria-labelledby="dropdownMenuButtonNotification"
              >
                <a className="dropdown-item" href="#nogo">
                  Alerts
                </a>
                <a className="dropdown-item" href="#nogo">
                  Another action
                </a>
                <a className="dropdown-item" href="#nogo">
                  Something else here
                </a>
              </div>
            </li>
            <li style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
              <FaUser /> {admin.firstName}{" "}
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                cursor: "pointer",
              }}
              onClick={() => onLogout()}
            >
              {" "}
              <PiSignOutBold size={22} />
              Sign Out
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
