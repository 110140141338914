const apiendpoint = "https://crashassistprod.claimtec.co.za";

let apiService = {
  adminSignin: `${apiendpoint}/api/auth/signin`,
  getAllCaseHistory: `${apiendpoint}/api/dreamtec-case-history/getAll`,
  getCaseById: `${apiendpoint}/api/dreamtec-case-history/get`,
  getServiceByCaseId: `${apiendpoint}/api/service-history/get`,
  createAgent: `${apiendpoint}/api/user/create`,
  getAllAgents: `${apiendpoint}/api/user/all`,
  deleteAgent: `${apiendpoint}/api/user`,

  getPaidNotificationStatus: `${apiendpoint}/notifications/getpaidnotifications?readStatus=false`,

  updatePaidNotificationStatus: `${apiendpoint}/notifications/updateReadStatus`,

  getDreamCaseHistoryByGetCase: `${apiendpoint}/api/dreamtec-case-history/getcase`,

  updateService: `${apiendpoint}/api/service-history/update`,
  cancelService: `${apiendpoint}/api/user/service/cancel`,

  updateCaseVisitStatus: `${apiendpoint}/api/dreamtec-case-history/updateVisitStatus`,
  updateServiceVisitStatus: `${apiendpoint}/api/service-history/updateVisitStatus`,

  getAllRates: `${apiendpoint}/api/rates`,
  updateRateById: `${apiendpoint}/api/update`,
  addRates: `${apiendpoint}/api/save`,
};

export default apiService;
