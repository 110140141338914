import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import "./AuthLayout.scss";
import MainServices from "../../services/MainServices";
import axios from "axios";
import { useNotifications } from "../../context/Notification";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { MdDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function AuthLayout({ children }) {
  const navigate = useNavigate();
  const {
    getPaidNotificationStatus,
    updatePaidNotificationStatus,
    getDreamCaseHistoryByGetCase,
  } = MainServices();
  const { notifications, setNotifications, setRefreshCases } =
    useNotifications();
  const [caseId, setCaseId] = useState();
  const [caseItem, setCaseItem] = useState([]);
  const [openModals, setOpenModals] = useState([]);
  const audioRef = useRef(null);

  const getPaymentNotification = async () => {
    try {
      const response = await axios.all([getPaidNotificationStatus()]);
      if (response[0]) {
        let resData = response[0];
        if (resData.success) {
          resData = resData.data ? resData.data : [];
          if (resData.length > 0) {
            console.log(resData);
            setNotifications(() => [...resData]);
            setCaseId(resData[0].caseId);
            setOpenModals(resData.map((_, index) => index));
            if (audioRef.current) {
              audioRef.current
                .play()
                .catch((error) => console.log("Play failed", error));
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNotification = async (id) => {
    const notification = notifications.find((notifi) => notifi.id === id);
    const details = { id: notification.id, status: "true" };
    try {
      const response = await axios.all([updatePaidNotificationStatus(details)]);
      if (response[0]) {
        let resData = response[0];
        if (resData.success) {
          setNotifications(notifications.filter((notifi) => notifi.id !== id));
          console.log(resData);
          setOpenModals(openModals.filter((modalId) => modalId !== id));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentNotification();
    const intervalId = setInterval(getPaymentNotification, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleCloseModal = (id) => {
    setOpenModals(openModals.filter((modalId) => modalId !== id));
    updateNotification(id);
    setRefreshCases(true);
  };

  const getSingleCase = async (caseId) => {
    const details = {
      caseId: caseId,
    };
    await axios
      .all([getDreamCaseHistoryByGetCase(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};

            setCaseItem(resData);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in retreving case");
      });
  };

  useEffect(() => {
    if (caseId) {
      getSingleCase(caseId);
    }
  }, [caseId]);

  return (
    <div className="auth-layout">
      <div className="header-layout">
        <Header />
      </div>
      <div className="main-layout">
        <div className="content-layout">{children}</div>
      </div>
      {notifications.map((notification, index) => (
        <Modal
          centered
          key={index}
          isOpen={openModals.includes(index)}
          toggle={() => handleCloseModal(notification.id)}
          size="large"
        >
          <ModalHeader toggle={() => handleCloseModal(notification.id)}>
            Payment Notification
          </ModalHeader>
          <ModalBody>
            <div className="success-payment-modal">
              <div className="okay-icon">
                <MdDone size={35} color="#fff" />
              </div>
              <h5
                style={{ textAlign: "center", fontFamily: "Montserrat-Medium" }}
              >
                Payment Received!
              </h5>
              <p>
                You have recieved Payment from case{" "}
                <strong>{caseItem?.caseReference} </strong> !
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                >
                  <label htmlFor="amount">Amount Paid: </label>
                  <p style={{ marginBottom: "0" }}>
                    <strong>{caseItem?.paymentAmount}</strong>
                  </p>{" "}
                </div>
              </div>

              <p
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  navigate("/notification-details", {
                    state: { caseId: notification.caseId },
                  });
                  handleCloseModal(notification.id);
                }}
              >
                <strong>Click here for details</strong>
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => handleCloseModal(notification.id)}
            >
              Mark as Read
            </Button>
          </ModalFooter>
        </Modal>
      ))}
    </div>
  );
}
