import React, { createContext, useContext, useState } from "react";

const Notification = createContext();

export const useNotifications = () => useContext(Notification);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [refreshCases, setRefreshCases] = useState(false);

  return (
    <Notification.Provider
      value={{
        notifications,
        setNotifications,
        refreshCases,
        setRefreshCases,
      }}
    >
      {children}
    </Notification.Provider>
  );
};
