import React, { useState, useEffect, useCallback } from "react";
import "./Operations.scss";
import axios from "axios";
import MainServices from "../../services/MainServices";
import {
  ButtonLoader,
  CustomerPortalLoader,
  LazyLoader,
} from "../../assets/loaders";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaUser } from "react-icons/fa";
import "react-tabs/style/react-tabs.css";
import { RiTeamFill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import { FaEnvelope, FaTruck } from "react-icons/fa";
import { deBounce } from "../../assets/functions/TimingFuntions";

import {
  FaBullhorn,
  FaCalendarAlt,
  FaFileAlt,
  FaCar,
  FaQuestionCircle,
} from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import { useNotifications } from "../../context/Notification";

const Dashboard = () => {
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [allCases, setAllCases] = useState([]);
  const [searchString, setSearchString] = useState();

  const { refreshCases } = useNotifications();

  const { getAllCaseHistory, getCaseByReference } = MainServices();
  const navigate = useNavigate();

  const { admin } = useAuth();

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "UTC",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  //get all cases api
  const getAllCases = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 6,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            setAllCases(resData);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const searchByReference = async (referenceId) => {
    if (!referenceId) {
      getAllCases();
      return;
    }

    setSearchLoading(true);

    const details = {
      reference: referenceId,
    };
    try {
      const res = await getCaseByReference(details);
      if (res && res.success) {
        setAllCases([res.data]);
        setSearchLoading(false);
      }
    } catch (error) {
      console.log("An error occurred in retrieving services", error);
    } finally {
      setSearchLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
      searchByReference(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    getAllCases();
  }, [refreshCases]);

  return (
    <div className="main-content-container">
      <div className="sidecontent">
        <Tabs>
          <TabList>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "12px",
                }}
              >
                {allCases.length}
              </span>
              Unclaimed Cases
            </Tab>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "12px",
                }}
              >
                0
              </span>
              Tasks
            </Tab>
          </TabList>

          <TabPanel>
            {dashboardLoading ? (
              <CustomerPortalLoader />
            ) : (
              <>
                <div className="cases-container">
                  <div
                    className="search-bar"
                    style={{ justifyContent: "flex-start", marginLeft: "1rem" }}
                  >
                    <input
                      type="text"
                      placeholder="Search Case by Reference ID.."
                      onChange={handleSearchChange}
                      style={{ width: "100%" }}
                    />
                    {searchLoading ? (
                      <p className="loading-input-text">
                        <ButtonLoader color="rgb(213, 36, 41)" />
                      </p>
                    ) : (
                      ""
                    )}
                    <button
                      className="filter-button"
                      onClick={() => searchByReference(searchString)}
                    >
                      <IoSearchSharp size={23} />
                    </button>
                  </div>
                  {allCases && allCases.length > 0 ? (
                    <>
                      {allCases.map((caseItem, index) => {
                        const requestData = JSON.parse(
                          caseItem.requestJsonData
                        );

                        return (
                          <div
                            className={
                              caseItem.visit === false
                                ? `info-box-unread`
                                : `info-box`
                            }
                            key={index}
                          >
                            <div className="header">
                              <FaBullhorn className="icon" />
                              <div className="details">
                                <p
                                  className="code-link"
                                  style={{
                                    marginBottom: "0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate("/case-details", {
                                      state: caseItem,
                                    })
                                  }
                                >
                                  {caseItem.caseReference}
                                </p>
                                <span className="policy">
                                  {" "}
                                  - {requestData.customer.firstName}
                                </span>
                                {caseItem.pyamentStatus == "Payment via App" ||
                                caseItem.pyamentStatus === null ? (
                                  <span className="payment-status-div">
                                    <p style={{ marginBottom: "0" }}>Unpaid</p>
                                  </span>
                                ) : (
                                  <span className="payment-status-div-paid">
                                    <p style={{ marginBottom: "0" }}>
                                      {caseItem.pyamentStatus}
                                    </p>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="timestamp">
                              <FaCalendarAlt className="icon" />
                              <span>
                                {" "}
                                {formatDate(requestData.dateOfIncident)}
                              </span>
                            </div>
                            <hr />
                            <div className="body">
                              <div>
                                <div className="info-row">
                                  <FaFileAlt className="icon" />
                                  <span className="label">Account</span>
                                  <span className="value">
                                    {requestData.customer.firstName}
                                  </span>
                                </div>
                                <div className="info-row">
                                  <FaFileAlt className="icon" />
                                  <span className="label">Policy</span>
                                  <span className="value"> </span>
                                </div>
                              </div>
                              <div>
                                <div className="info-row">
                                  <FaFileAlt className="icon" />
                                  <span className="label">Origin</span>
                                  <a href="#" className="value">
                                    {requestData.customer.firstName}
                                  </a>
                                </div>
                                <div className="info-row">
                                  <FaCar className="icon" />
                                  <span className="label">Vehicle</span>
                                  <span className="value">
                                    {" "}
                                    {requestData.vehicle.make +
                                      " " +
                                      requestData.vehicle.model}
                                  </span>
                                </div>
                                <div className="info-row">
                                  <FaCar className="icon" />
                                  <span className="label">Licence </span>
                                  <span className="value">
                                    <strong
                                      style={{
                                        color: "#007bff",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {" "}
                                      {requestData.vehicle.licensePlateNumber}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="footer">
                              <FaQuestionCircle className="icon" />
                              <a href="#" className="integration-link">
                                Integration
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <h5>No Cases Found</h5>
                    </>
                  )}
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel>
            <div>
              <div className="search-bar">
                <input type="text" placeholder="Search..." />
                <button className="filter-button">
                  <IoSearchSharp size={23} />
                </button>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="main-dashboard">
        <>
          <div className="count-container">
            <Tabs>
              <TabList>
                <Tab>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    {" "}
                    <FaUser /> My Open Cases
                  </span>
                </Tab>
                <Tab>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    <RiTeamFill /> My Teams Open Cases
                  </span>
                </Tab>
                <Tab>
                  {" "}
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    {" "}
                    <IoSearchSharp />
                    Search
                  </span>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="case-info-box">
                  <div className="left-section">
                    <div className="header">
                      <FaEnvelope className="icon" />
                      <div className="details">
                        <a href="#" className="code-link">
                          C002VZC -{" "}
                          <span style={{ color: "#000", fontSize: "14px" }}>
                            Mohammed Muzaffar Khan
                          </span>
                        </a>
                        <span className="date-time">2024/02/27 07:59</span>
                      </div>
                    </div>
                    <div className="info">
                      <div className="info-row">
                        <FaFileAlt className="value-icon" />
                        <span className="label">Opened By</span>
                        <span className="value">{admin.firstName}</span>
                      </div>
                      <div className="info-row">
                        <FaCar className="value-icon" />
                        <span className="label">Vehicle</span>
                        <span className="value">AP21D7299</span>
                      </div>
                      <div className="info-row">
                        <FaFileAlt className="value-icon" />
                        <span className="label">Policy</span>
                        <span className="value"> </span>
                      </div>
                      <div className="info-row">
                        <FaQuestionCircle className="value-icon" />
                        <a href="#" className="operations-link">
                          Operations
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="right-section">
                    <span className="time-ago">2 months ago</span>
                    <div className="service-info">
                      <div className="service-header">
                        <FaTruck className="icon" />
                        <span className="service-name">Accident Tow</span>
                        <span className="service-date">2024/05/28 18:46</span>
                        <span className="service-status">
                          Awaiting Manual Assignment
                        </span>
                      </div>
                      <div className="progress">
                        <span className="step active">Dispatch</span>
                        <span className="step">Arrive </span>
                        <span className="step">Complete </span>
                        <span className="step">Confirm </span>
                        <span className="step">Finalize</span>
                        <span className="step">Finance</span>
                        <span className="step">Verify</span>
                        <span className="step">Invoice</span>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <h2>content</h2>
              </TabPanel>
              <TabPanel>
                <h2>content</h2>
              </TabPanel>
            </Tabs>
          </div>
        </>
      </div>
    </div>
  );
};

export default Dashboard;
