import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import Dashboard from "../../containers/operations/Operations";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import Layout from "../layouts/Layout";
import Login from "../../containers/login";

import * as Storage from "../../store/LocalStorage";
import CreateAgent from "../../containers/createAgent";
import Agents from "../../containers/agents";
import CaseDetails from "../../containers/caseDetails";
import NotificationDetails from "../../containers/NotificationDetails";
import CaseRates from "../../containers/caseRates";

const CustomRoutes = () => {
  const [loading, setLoading] = useState(true);
  const config = useConfig();
  const { admin, updateUserContext } = useAuth();
  const navigate = useNavigate();

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);

    session = JSON.parse(session);
    if (session) {
      updateUserContext(session);
      setLoading(false);
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  const hasLoaded = config.status === "success" || config.status === "error";

  useEffect(() => {
    if (!hasLoaded) return;
    checkSession();

    // eslint-disable-next-line
  }, [hasLoaded, config]);

  return (
    <>
      {admin ? (
        <AuthLayout>
          <Routes>
            <Route path="/*" element={<Dashboard />} />
            <Route path="/operations" element={<Dashboard />} />
            <Route path="/case-details" element={<CaseDetails />} />
            <Route
              path="/notification-details"
              element={<NotificationDetails />}
            />

            <Route path="/create-agent" element={<CreateAgent />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/case-rates" element={<CaseRates />} />
          </Routes>
        </AuthLayout>
      ) : (
        <Layout>
          <Routes>
            <Route path="/*" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};

export default CustomRoutes;
